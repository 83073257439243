import gql from "graphql-tag";

export const USER_REGISTRATIONS = gql`
query MyQuery($status: String, $group: String) {
  users(where: {_and: {status: {_eq: $status}, assigned_group: {_ilike: $group}}}) {
    id
    current_location
    email
    first_name
    gender
    last_name
    mobile_number
    new_business_name
    status
    category
    wallet_balance
    house_no
    floor_no
    building_apartment_name
    street_name
    sitio_or_purok
    subdivision
    barangay
    city_municipality
    user_items {
      id
      item
      cost
      quantity
      suggested_supplier_vendor
      unit_price
      user_id
    }
  }
}
`

export const USER_DETAILS = gql`
query MyQuery($id: uuid) {
  users(where: {id: {_eq: $id}}) {
    barangay
    birth_date
    building_apartment_name
    business_address
    business_certificates
    business_months_years
    cellphone_number
    chosen_business_reason
    chosen_business_will_be_successful_reason
    city_municipality
    civil_status
    classification
    current_location
    disadvantage_group
    email
    experiences
    family_count
    fb_messenger
    financial_support_name
    first_name
    gender
    has_business
    has_received_financial_support
    has_valid_id
    house_no
    id
    id_expiry
    id_number
    id_issuer
    is_business_registered
    is_lot_owned
    landline_number
    last_name
    mobile_number
    monthly_income
    name_of_spouse
    new_business_name
    number_of_employees
    pin
    place_of_birth
    reason_to_be_helped
    sitio_or_purok
    spouse_birth_date
    spouse_contact_number
    street_name
    subdivision
    type
    wallet_balance
    status
    estimated_daily_sales
    chosen_materials_and_where_to_get_them 
    packaging
    has_lot_available
    equipments_and_machinery
    people_to_hire 
    target_per_day_week 
    how_to_inspect_product_quality
    where_to_sell
    product_uniqueness 
    is_product_of_cebu_reason 
    category 
    user_picture 
    user_id_picture 
    industry 
    business_mobile 
    business_landline 
    business_website_social 
    business_rent 
    business_rent_owner 
    business_rent_address 
    business_rent_owner_number 
    receive_date 
    receive_amount
    receive_agency 
    receive_usage 
    application_purpose 
    total_monthly_income 
    total_monthly_product_sales 
    net_monthly_income 
    total_net_worth 
    monthly_rent_cost 
    other_expenses 
    other_expense_debt 
    business_kind 
    assigned_group
    id_type
    floor_no
    user_business_certificates {
      name
      user_id
    }
    user_items {
      id
      item
      cost
      quantity
      suggested_supplier_vendor
      unit_price
      user_id
    }
  }
}
`


export const USER_DATA = gql`
query MyQuery {
  portal_users {
    id
    permission
    username
    group
  }
}
`

export const AGGREGATES = gql`
query MyQuery {
  total: users_aggregate {
    aggregate {
      count
    }
  }
  pending: users_aggregate(where: {status: {_eq: "pending"}}) {
    aggregate {
      count
    }
  }
  accepted: users_aggregate(where: {status: {_eq: "accept"}}) {
    aggregate {
      count
    }
  }
  screened: users_aggregate(where: {status: {_eq: "screened"}}) {
    aggregate {
      count
    }
  }
  approved: users_aggregate(where: {status: {_eq: "approved"}}) {
    aggregate {
      count
    }
  }
  denied: users_aggregate(where: {status: {_eq: "denied"}}) {
    aggregate {
      count
    }
  }
}
`


export const ADMIN_BM_ESTIMATES = gql`
query MyQuery {
  portal_users(where: {permission: {_eq: "bm"}}, order_by: {group_users_aggregate: {count: desc}}) {
    username
    group
    accepted_allocation: group_users_aggregate(where: {status: {_eq: "accept"}}, order_by: {}) {
      aggregate {
        count
        sum {
          wallet_balance
        }
      }
    }
    balance: group_users(where: {status: {_eq: "approved"}}) {
      category
      wallet_balance
    }
    group_aggregate: group_users_aggregate(where: {status: {_eq: "approved"}}) {
      nodes {
        merchants_sales_aggregate {
          aggregate {
            sum {
              amount
            }
          }
        }
      }
    }
    pending_allocation: group_users_aggregate(where: {status: {_eq: "pending"}}) {
      aggregate {
        count
      }
    }
    screened_allocation: group_users_aggregate(where: {status: {_eq: "screened"}}) {
      aggregate {
        count
        sum {
          wallet_balance
        }
      }
    }
    approved_allocation: group_users_aggregate(where: {status: {_eq: "approved"}}) {
      aggregate {
        count
        sum {
          wallet_balance
        }
      }
    }
    pending_cat_a_count: group_users_aggregate(where: {category: {_eq: "a"}, status: {_eq: "pending"}}) {
      aggregate {
        count
      }
    }
    pending_cat_b_count: group_users_aggregate(where: {category: {_eq: "b"}, status: {_eq: "pending"}}) {
      aggregate {
        count
      }
    }
    pending_cat_c_count: group_users_aggregate(where: {category: {_eq: "c"}, status: {_eq: "pending"}}) {
      aggregate {
        count
      }
    }
    cat_a_count: group_users_aggregate(where: {category: {_eq: "a"}}) {
      aggregate {
        count
      }
    }
    cat_b_count: group_users_aggregate(where: {category: {_eq: "b"}}) {
      aggregate {
        count
      }
    }
    cat_c_count: group_users_aggregate(where: {category: {_eq: "c"}}) {
      aggregate {
        count
      }
    }
    total_pending: group_users_aggregate(where: {status: {_eq: "pending"}}) {
      aggregate {
        count
      }
    }
    total_accepted: group_users_aggregate(where: {status: {_eq: "accept"}}) {
      aggregate {
        count
      }
    }
    total_screened: group_users_aggregate(where: {status: {_eq: "screened"}}) {
      aggregate {
        count
      }
    }
    total_approved: group_users_aggregate(where: {status: {_eq: "approved"}}) {
      aggregate {
        count
      }
    }
    total_applications: group_users_aggregate {
      aggregate {
        count
      }
    }
    cat_a_pending: group_users_aggregate(where: {status: {_eq: "pending"}, category: {_eq: "a"}}) {
      aggregate {
        count
      }
    }
    cat_b_pending: group_users_aggregate(where: {status: {_eq: "pending"}, category: {_eq: "b"}}) {
      aggregate {
        count
      }
    }
    cat_c_pending: group_users_aggregate(where: {status: {_eq: "pending"}, category: {_eq: "c"}}) {
      aggregate {
        count
      }
    }
    cat_a_accept: group_users_aggregate(where: {status: {_eq: "accept"}, category: {_eq: "a"}}) {
      aggregate {
        count
      }
    }
    cat_b_accept: group_users_aggregate(where: {status: {_eq: "accept"}, category: {_eq: "b"}}) {
      aggregate {
        count
      }
    }
    cat_c_accept: group_users_aggregate(where: {status: {_eq: "accept"}, category: {_eq: "c"}}) {
      aggregate {
        count
      }
    }
    cat_a_screened: group_users_aggregate(where: {status: {_eq: "screened"}, category: {_eq: "a"}}) {
      aggregate {
        count
      }
    }
    cat_b_screened: group_users_aggregate(where: {status: {_eq: "screened"}, category: {_eq: "b"}}) {
      aggregate {
        count
      }
    }
    cat_c_screened: group_users_aggregate(where: {status: {_eq: "screened"}, category: {_eq: "c"}}) {
      aggregate {
        count
      }
    }
    cat_a_approved: group_users_aggregate(where: {status: {_eq: "approved"}, category: {_eq: "a"}}) {
      aggregate {
        count
      }
    }
    cat_b_approved: group_users_aggregate(where: {status: {_eq: "approved"}, category: {_eq: "b"}}) {
      aggregate {
        count
      }
    }
    cat_c_approved: group_users_aggregate(where: {status: {_eq: "approved"}, category: {_eq: "c"}}) {
      aggregate {
        count
      }
    }
  }
}

`

export const ADMIN_MERCHANTS = gql`
query MyQuery {
  merchants {
    name
    username
    merchants_sales_aggregate(where: {status: {_eq: "REIMBURSED"}}) {
      aggregate {
        count
        sum {
          amount
        }
      }
    }
  }
}
`

export const ADMIN_CPG_USERS = gql`
query MyQuery {
  users{
    first_name
    last_name
    wallet_balance
    user_items {
      quantity
      item
      unit_price
      cost
      suggested_supplier_vendor
    }
    merchants_sales_aggregate {
      aggregate {
        count
        sum {
          amount
        }
      }
    }
  }
}
`


export const ALLOCATIONS = gql`
query MyQuery {
  cat_a_pending: users_aggregate(where: {status: {_eq: "pending"}, category: {_eq: "a"}}) {
    aggregate {
      count
    }
  }
  cat_b_pending: users_aggregate(where: {status: {_eq: "pending"}, category: {_eq: "b"}}) {
    aggregate {
      count
    }
  }
  cat_c_pending: users_aggregate(where: {status: {_eq: "pending"}, category: {_eq: "c"}}) {
    aggregate {
      count
    }
  }
  cat_a_accept: users_aggregate(where: {status: {_eq: "accept"}, category: {_eq: "a"}}) {
    aggregate {
      count
    }
  }
  cat_b_accept: users_aggregate(where: {status: {_eq: "accept"}, category: {_eq: "b"}}) {
    aggregate {
      count
    }
  }
  cat_c_accept: users_aggregate(where: {status: {_eq: "accept"}, category: {_eq: "c"}}) {
    aggregate {
      count
    }
  }
  cat_a_approved: users_aggregate(where: {status: {_eq: "approved"}, category: {_eq: "a"}}) {
    aggregate {
      count
    }
  }
  cat_b_approved: users_aggregate(where: {status: {_eq: "approved"}, category: {_eq: "b"}}) {
    aggregate {
      count
    }
  }
  cat_c_approved: users_aggregate(where: {status: {_eq: "approved"}, category: {_eq: "c"}}) {
    aggregate {
      count
    }
  }
}
`


export const USERS_SEARCH = gql`
query MyQuery($search: String) {
  users(where: {_or: [{first_name: {_ilike: $search}}, {last_name: {_ilike: $search}}], status: {_eq: "approved"}}, limit: 50) {
    id
    first_name
    last_name
    city_municipality
    user_picture
    assigned_group
  }
}
`