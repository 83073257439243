import React, { useRef, useState } from "react";
import { useContext } from "react";
import { AppContext, PageContext } from "../../lib/context";
import { Form, Input, Button, Spin, AutoComplete } from "antd";
import { useEffect } from "react";
import { useMemo } from "react";
import ReactToPrint from "react-to-print";
import { QRCode } from "react-qrcode-logo";
import { Offices } from "../../lib/offices";

class ComponentToPrint extends React.Component {
  render() {
    return (
      <div
        className="text-gray-700"
        style={{
          padding: 10,
          display: "flex",
          flexDirection: "row",
          width: 1150,
          flexWrap: "wrap",
          textAlign: "left",
          alignItems: "center",
        }}
      >
        {this.props.selected.map((id, key) => (
          <div
            onClick={() => this.props.handleDelete(key)}
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop:
                (key % 12 === 0 || (key % 12 === 1 && key > 12)) && key !== 0
                  ? 130
                  : 10,
              // width: "50%",
            }}
          >
            <div
              style={{
                backgroundImage: `url(${require("../../assets/card-v2.png")})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                height: 220,
                width: 400,
                marginRight: 2,
                objectFit: "fill",
                position: "relative",
              }}
            >
              {/* <div style={{
                  position: "absolute",
                  height: 71,
                  width: 70,
                  right: 58,
                  top: 4,
                }}> */}
              <QRCode
                value={id?.id}
                logoWidth={40}
                logoHeight={40}
                logoImage={require("../../assets/cebu-province-logo.jpg")}
              />
              {/* </div> */}
              <img
                src={id?.user_picture}
                alt={id?.id}
                className="h-20 w-20"
                style={{
                  objectFit: "cover",
                  width: 78,
                  height: 78,
                  position: "absolute",
                  left: 14,
                  top: 121,
                }}
              />
              <p
                style={{
                  position: "relative",
                  left: 104,
                  top: 110,
                  fontSize: 8,
                }}
              >{`${id?.first_name?.toUpperCase()} ${id?.last_name?.toUpperCase()}`}</p>
              <p
                style={{
                  position: "relative",
                  left: 104,
                  top: 126,
                  fontSize: 8,
                }}
              >{`${id?.id}`}</p>
              <p
                style={{
                  position: "relative",
                  left: 104,
                  top: 140,
                  fontSize: 8,
                }}
              >{`${id?.city_municipality?.toUpperCase()}`}</p>
              <p
                style={{
                  position: "relative",
                  left: 104,
                  top: 154,
                  fontSize: 8,
                }}
              >{`${
                Offices?.find((el) => el.OFFICE === id?.assigned_group)?.OFFICE
              } - ${
                Offices?.find((el) => el.OFFICE === id?.assigned_group)?.NAME
              }`}</p>
            </div>
          </div>
        ))}
      </div>
    );
  }
}

const DashboardView = (props) => {
  const {
    data,
    loading,
    onSelect,
    onSearch,
    selected,
    setSelected,
    options,
    value,
    handleDelete,
  } = useContext(PageContext);

  const componentRef = useRef();
  return (
    <div className="flex flex-col h-auto pt-12">
      <p className="text-2xl mb-10">PRINT ID</p>
      <div className="mt-10">
        <p>Search user by First name or Last name</p>
        <AutoComplete
          options={options}
          onSelect={onSelect}
          onSearch={onSearch}
          placeholder="Search by name"
          allowClear
          value={value}
        >
          <Input.Search placeholde="Search by name" />
        </AutoComplete>

        {selected?.length > 0 && (
          <div className="text-left ml-16 text-blue-400 font-bold">
            <ReactToPrint
              trigger={() => <button>Print</button>}
              content={() => componentRef.current}
            />
            <ComponentToPrint
              ref={componentRef}
              selected={selected}
              handleDelete={handleDelete}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default DashboardView;
